"use strict";
/**
 * The file contents for the current environment will overwrite these during
 * build. The build system defaults to the dev environment which uses
 * `environment.ts`, but if you do `ng build --env=prod` then
 * `environment.prod.ts` will be used instead. The list of which env maps to
 * which file can be found in `angular-cli.json`. `.env.ts` is generated by the
 * `npm run env` command import env from './.env';
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @ignore
 */
var production = 'true';
var serverUrl = 'https://ruckit-platform-staging-02-f0121c02ce3a.herokuapp.com/api/';
var imageServerUrl = 'http://ruckit-platform-staging.s3-website-us-east-1.amazonaws.com';
var resizeImageServerUrl = 'https://d8ojjhfh4a4yx.cloudfront.net';
var errorTrackingEnv = 'staging';
var googleMapsKey = 'AIzaSyBnyQsblAiJeVVAHKQyo76Nx8PVp-N2mLU';
var applicationVersion = 'aa3df266f202369f537342f64e9937d7c4a7bf0f';
var scaleitUrl = 'https://api.scaleitusa.com/api/ruckit/v1/';
var s3ServerUrl = 'https://s3.amazonaws.com/ruckit-platform-staging/';
var ticketManagerUrl = 'https://tickets-dev.goruckit.com';
var ordersUrl = 'https://orders.goruckit.com';
exports.environment = {
    production: production === 'true',
    serverUrl: serverUrl,
    imageServerUrl: imageServerUrl,
    resizeImageServerUrl: resizeImageServerUrl,
    s3ServerUrl: s3ServerUrl,
    scaleitUrl: scaleitUrl,
    ticketManagerUrl: ticketManagerUrl,
    ordersUrl: ordersUrl,
    errorTrackingEnv: errorTrackingEnv,
    applicationVersion: applicationVersion,
    defaultLanguage: 'en-US',
    supportedLanguages: [
        'en-US',
        'fr-FR'
    ],
    googleMapsKey: googleMapsKey
};
